<template>
    <b-card class="prozess-digital-signature">
      <div>
        <div class="mb-1 d-flex">
            <div style="width: 50%;">
                <h2> {{ $t('Generate Document') }} </h2>
            </div>
            <div style="width: 50%; display: flex; justify-content: flex-end;">
                <button id="cancel-create-button" class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary"
                @click="cancelCreate()">
                    <span>{{ $t('Cancel') }}</span>
                </button>
            </div>
        </div>
        <div v-if="onTemplatesLoad" class="d-flex justify-content-center">
          <b-spinner small />
        </div>
        <div v-else>
            <div class="temp-sel">
                <p style="margin: 0px">{{ $t('Select Document Template') }}</p>
                <div class="d-flex">
                    <ProzessSelect
                        v-model="selectedTemplate"
                        style="width: 420px;"
                        :options="documentTemplateOptions"
                    />
                    <button id="preview-temp-button"
                    class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary"
                    @click="previewTemplate()"
                    style="margin-left: 8px; min-width: 100px;"
                    :class="{
                        'icon-disabled': selectedTemplate == null || onLoadTemplateData
                    }"
                    >
                        <span v-if="!onLoadTemplateData">{{ $t('Preview') }}</span>
                        <b-spinner v-else small/>
                    </button>
                </div>
            </div>
            <div v-if="wizard!=null && this.baseSelectedTemplateId !=null && this.baseSelectedTemplateId === this.selectedTemplate?.key" style="margin-top: 12px;">
                <br/>
                <h3>{{ contractTemplate.name }}</h3>
                <div v-for="(pageNumber, i) of wizard.pages"
                    :key="`pdf-page-${i}`" class="mb-1"
                    style="background-color: rgba(0,0,0,0.02); padding: 8px 10px; border-radius: 10px">
                    <div class="d-flex">
                        <p class="mb-0" style="margin-top:2px; font-size: 1.2rem;">{{ `Page ${pageNumber}` }}</p>
                    </div>
                    <div
                        v-for="(pageField, i2) of wizard.pageFields[pageNumber]"
                        :key="`page-field-${i2}`"
                        style="margin-left: 20px; margin-bottom:8px;margin-top:6px; height: 40px; border-radius: 8px; align-items: center; padding:12px; background-color: rgba(255,255,255)"
                        class="form-field-item d-flex"
                        >
                        <div style="width: 30%;">
                            <p style="font-size: 1rem; margin: 0px">{{ pageField.acroKey }}</p>
                        </div>
                        <div style="width: 30%; display: flex; justify-content: center;">
                            <p style="font-size: 1rem; margin: 0px; background-color: gainsboro; border-radius: 6px; color: gray; padding-left: 6px; padding-right: 6px;">
                                {{ $t(getInputTypeLabel(wizard.formFieldMap[pageField.acroKey]?.type)) }}</p>
                        </div>
                        <div style="width: 40%;">
                            <prozess-input style="padding-top: 18px" v-if="pageField.primary && wizard.formFieldMap[pageField.acroKey]?.type === 'manual'"
                            v-model="overrideInputs[pageField.acroKey]"/>

                            <p style="padding-top: 10px; color: gray;" v-if="pageField.primary && wizard.formFieldMap[pageField.acroKey]?.type === 'map'">{{ getMappingLabel(pageField.acroKey) }}</p>

                            <p v-if="!pageField.primary" style="width: fit-content; padding:2px; padding-left: 6px; padding-right: 6px; margin-top:14px; border-radius: 6px;font-size: 0.9rem; font-weight: 500; color:white ; background-color: lightgray;">
                                <span>{{ $t('Mapped on Page ') }}</span>
                                <span>{{ pageField.primaryPage }}</span>
                            </p>
                        </div>
                </div>
                </div>

                <div style="margin-top: 12px; background-color: rgb(251, 251, 251); padding: 12px; border-radius: 10px;">
                    <b-form-checkbox
                        inline
                        v-model="overrideName"
                        @change="handleOverrideCbChange"
                        >
                        {{ $t('Override Document Name')}}
                    </b-form-checkbox>
                    <prozess-input v-if="overrideName" v-model="documentName"/>
                    <prozess-input v-else v-model="selectedTemplate.documentName" disabled="true"/>
                </div>

                <div style="margin-top: 14px; width: 100%; display: flex; justify-content: center;">
                    <button id="cancel-create-button" style="margin-left: 8px; width: 30%; min-width: 120px;" class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary"
                        @click="generate()"
                        :class="{ 'icon-disabled': selectedTemplate == null || this.wizard == null || this.baseSelectedTemplateId !== this.selectedTemplate?.key}"
                        >
                        <span v-if="!onGenerateDocument">{{ $t('Generate and Save') }}</span>
                        <b-spinner v-else small />
                    </button>
                </div>
            </div>
        </div>
      </div>
    </b-card>
  </template>

  <script>
  import contractTemplateService from '@/services/contractTemplate'
  import moment from 'moment'
  import { GenericTabs, Schema } from '@/constants/app'

  export default {
    props: {
        cancelCreate: {
            type: Function,
            required: true,
        },
        createComplete: {
            type: Function,
            required: true,
        },
        generateAllRelation: {
            type: Boolean,
            required: true,
        },
    },
    data() {
      return {
        onTemplatesLoad: false,
        onLoadTemplateData: false,
        showMessage: false,
        loading: false,
        selectedTemplate: null,
        baseSelectedTemplateId: null,
        documentTemplateOptions: [],
        contractTemplate: null,
        overrideInputs: {},
        overrideName: false,
        documentName: '',
        onGenerateDocument: false,
      }
    },
    created() {
      this.getInitialData()
    },
    methods: {
        getMappingLabel(acroKey) {
            return this.wizard.formFieldMap[acroKey].schema.concat('.')
                            .concat(this.wizard.formFieldMap[acroKey].key).concat('.')
                            .concat(this.wizard.formFieldMap[acroKey].fieldKey)
        },
        getInputTypeLabel(key) {
            switch (key) {
                case 'map': return 'Mapping'
                case 'manual': return 'Manual Input'
                case 'auto': return 'Auto-set'
                default: return ''
            }
        },
        async setTemplateData(templateId) {
            this.wizard = null
            this.baseSelectedTemplateId = templateId
            this.onLoadTemplateData = true
            this.overrideInputs = {}
            const { response } = await this.$async(contractTemplateService.getTemplateById(templateId))
            this.wizard = {
                pageFields: response.data.pageFields,
                pages: Object.keys(response.data.pageFields),
                formFieldMap: response.data.formFieldMap,
            }
            this.contractTemplate = response.data.template.contractTemplate
            this.onLoadTemplateData = false
        },
        previewTemplate() {
            this.setTemplateData(this.selectedTemplate.key)
        },
        async generate() {
            this.onGenerateDocument = true
            const generateDocumentRequest = {
                companyId: this.$route.params.id,
                templateId: this.selectedTemplate.key,
                generateAllRelation: this.generateAllRelation,
                documentName: this.overrideName ? this.documentName : this.selectedTemplate.documentName,
                overrideInputs: JSON.parse(JSON.stringify(this.overrideInputs)),
            }
            await this.$async(contractTemplateService.generate(generateDocumentRequest)).then(async res => {
                const docLinkReqRes = res.response.data
                const docUuid = await contractTemplateService.linkDocument(docLinkReqRes)
                    this.createComplete()
            }).finally(() => {
                this.onGenerateDocument = false
            })
        },
      async getInitialData() {
        this.getTemplates()
      },
        async handleOverrideCbChange(overrid) {
        if (overrid) {
            this.documentName = this.selectedTemplate.documentName
        }
        },
      async getTemplates() {
        this.onTemplatesLoad = true
        const { response } = await this.$async(contractTemplateService.getAll())
        if (this.generateAllRelation) {
            const subRes = await this.$async(contractTemplateService.getSubsidiaries(this.$route.params.id))
            this.subsidiaryList = subRes.response.data.subsidiaryList
        }
        this.documentTemplateOptions =
            response.data.pageItems.map(t => ({
                        key: t.id,
                        label: t.name,
                        documentName: `${t.name.replace(/\s+/g, '-').toLowerCase()}-${moment(new Date()).format('YYYY-MM-DD')}`,
            }))
        this.onTemplatesLoad = false
        },
    },
  }
  </script>

  <style lang="scss">
  @import '@/assets/scss/variables/_variables.scss';
  @import '@/assets/scss/master-variables.scss';
  </style>
